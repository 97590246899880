import { isBrowser, checkUtmSourceStartsWith } from "../../utils";

export function slots() {
  // Need for build version as window undefined
  if (!isBrowser()) {
    return [];
  }

  const abtest = window.localStorage.getItem("abtest");
  // Define Ad unit names
  let RightColumnUnitName = "RightColumn1";
  let LeaderDesktopInfiniteUnitName = "LeaderDesktopInfinite";
  let LeaderBelowTitleUnitName = "LeaderBelowTitle";
  let StickyBannerUnitName = "StickyBannerDesktop";
  let MPUParallaxUnitName = "MPU_Parallax";
  let MPUTopRightUnitName = "MPUTopRight";
  let MPUCenterRightUnitName = "MPUCenterRight";
  if (window.screen.width < 812) {
    StickyBannerUnitName = "StickyBannerMobile";
  }

  if (abtest === "ab.tvl.fr.y") {
    RightColumnUnitName = "DESK_RightColumn";
    LeaderDesktopInfiniteUnitName = "DESK_LeaderDesktopInfinite";
    LeaderBelowTitleUnitName = "DESK_LeaderBelowTitle";
    StickyBannerUnitName = "DESK_StickyBannerDesktop";
    if (window.screen.width < 812) {
      MPUParallaxUnitName = "MOB_MPU_Parallax";
      LeaderBelowTitleUnitName = "MOB_LeaderBelowTitle";
      StickyBannerUnitName = "MOB_StickyBannerMobile";
      MPUTopRightUnitName = "MOB_MPUTopRight";
      MPUCenterRightUnitName = "MOB_MPUCenterRight";
    }
  }

  // Define Ad Unit Pathways
  let RightColumnPathway = "RightColumn1";
  let LeaderDesktopInfinitePathway = "LeaderDesktopInfinite";
  let MPUTopRightPathway = "MPUTopRight";
  let LeaderBelowTitlePathway = "LeaderBelowTitle";
  let StickyBannerPathway = "StickyBannerDesktop";
  let MPUParallaxPathway = "MPU_Parallax";
  let MPUCenterRightPathway = "MPUCenterRight";
  if (window.screen.width < 812) {
    StickyBannerPathway = "StickyBannerMobile";
  }

  if (abtest === "ab.tvl.fr.y") {
    RightColumnPathway = "TVLFR/DESK_RightColumn";
    LeaderDesktopInfinitePathway = "TVLFR/DESK_LeaderDesktopInfinite";
    LeaderBelowTitlePathway = "TVLFR/DESK_LeaderBelowTitle";
    StickyBannerPathway = "TVLFR/DESK_StickyBannerDesktop";
    if (window.screen.width < 812) {
      MPUParallaxPathway = "TVLFR/MOB_MPU_Parallax";
      LeaderBelowTitlePathway = "TVLFR/MOB_LeaderBelowTitle";
      StickyBannerPathway = "TVLFR/MOB_StickyBannerMobile";
      MPUTopRightPathway = "TVLFR/MOB_MPUTopRight";
      MPUCenterRightPathway = "TVLFR/MOB_MPUCenterRight";
    }
  }
  // prettier-ignore
  let adUnits = [
    {
      "condition": window.screen.width > 990 && !checkUtmSourceStartsWith('fb-'),
      "id": "RightColumn",
      "name": RightColumnUnitName,
      "path": `/76730613/${RightColumnPathway}`,
      "sizes": window.screen.width > 1100 ? [[300,250], [336,280], [320,100], [320,50], [300,100], [300,50], [300,600], [160, 600]] : [[160, 600]],
      "bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-fr', adUnitElementId: 'RightColumn', environment:'desktop', placement:`${RightColumnPathway}` } },
        { bidder: 'adform', params: { mid: 951073 } },
        //{ bidder: 'appnexus', params: { placementId: '18453922'}},
        { bidder: 'criteo', params: { networkId: 8147 } },
        //{ bidder: 'districtm', params: { placementId: 16005321 }},
        //{ bidder: 'districtmDMX', params: { dmxid: 367612, memberid:101868}},
        { bidder: 'ix', params: { siteId: 467052, size: [160, 600] }},
        // { bidder: 'ix', params: { siteId: 467052, size: [300, 250] }},
        // { bidder: 'ix', params: { siteId: 342235, size: [120, 600] }},
        // { bidder: 'ix', params: { siteId: 342235, size: [336, 280] }},
        ////{ bidder: 'justpremium', params: { zone: 115710 }},
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        //{ bidder: 'openx', params: { unit: '540973696', delDomain: 'lqr-d.openx.net' }},
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_fr_desktop_MPUTopRight1' } },
        //{ bidder: 'richaudience', params: { pid: '13F7fkQy0c', supplyType: 'site' }},
        { bidder: 'sovrn', params:{ tagid: 950684 } },
        { bidder: 'sovrn', params:{ tagid: 950685 } },
        { bidder: 'teads', params: {pageId: '113723', placementId: '131124'} },
        { bidder: 'triplelift',  params: { inventoryCode: "TravelcatchersFR_RightColumnHalfPage_Prebid" } },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        { bidder: 'smartadserver', params: { siteId: 536188, pageId: 1662538, formatId: 116584} },
        { bidder: 'taboola', params: { tagId: 'RightColumn', publisherId:  '1493292' } },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847493' } },
        { bidder: 'sharethrough', params: {pkey: 'z7RwLxiVHFVKvplgw0tRZwDf'} },
        { bidder: 'sharethrough', params: {pkey: 'SUWYRDIsEdtoFbNKCjoFPaAR'} },
        { bidder: 'sharethrough', params: {pkey: 'C5k4Sixvc5mEWOxFzxGRTQGj'} },
      ]
      
    },
    {
      "condition": checkStickybanner(),
      "id": "StickyBanner",
      "name": StickyBannerUnitName,
      "path": `/76730613/${StickyBannerPathway}`,
      "sizes": window.screen.width < 812 ? [[320, 50],[300,50],[360,50],[320,100],[300,100],[360,100]] : [[728, 90]],
      "bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-fr', adUnitElementId: 'StickyBanner', environment:'desktop', placement:`${StickyBannerPathway}` } },
        { bidder: 'adform', params: { mid: 1122080 } },
        //{ bidder: 'appnexus', params: { placementId: '18453924' } },
        { bidder: 'criteo', params: { networkId: 8147 } },
        // { bidder: 'districtm', params: { placementId: 18069185} },
        // { bidder: 'districtmDMX',params: {dmxid: 479167, memberid: 101868} },
        { bidder: 'ix', params: {siteId: 717144, size: [728,90]} },
        //{ bidder: 'justpremium', params: {zone: 115710, allow: ["ms"]} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        //{ bidder: 'openx', params: { unit: '540973702', delDomain: 'lqr-d.openx.net' } },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_fr_desktop_stickybanner' } },
        // { bidder: 'richaudience', params: { pid: '0mQBTSlzWP', supplyType: 'site' }},
        { bidder: 'sovrn', params:{ tagid: 950693 } },
        { bidder: 'teads', params: {pageId: '113723', placementId: '131124'} },
        { bidder: 'triplelift', params: { inventoryCode: "TravelcatchersFR_desktop_StickyBanner_Prebid"} },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        { bidder: 'smartadserver', params: { siteId: 536188, pageId: 1662538, formatId: 116616} },
        { bidder: 'taboola', params: { tagId: 'StickyBanner', publisherId:  '1493292' } },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847493' } },
        { bidder: 'sharethrough', params: {pkey: 'kYzVfUqwbPuvSuz1yj0DOOJ4'} }
      ],
      "mobile_bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-fr', adUnitElementId: 'StickyBanner', environment:'mobile', placement:`${StickyBannerPathway}` } },
        { bidder: 'adform', params: { mid: 951081 } },
        //{ bidder: 'appnexus', params: { placementId: '18453924' } },
        { bidder: 'criteo', params: { networkId: 8147 } },
        //{ bidder: 'districtm', params: { placementId: 18069185} },
        //{ bidder: 'districtmDMX',params: {dmxid: 479167, memberid: 101868} },
        { bidder: 'ix', params: {siteId: 467063, size: [320,50]} },
        { bidder: 'ix', params: {siteId: 467063, size: [300,50]} },
        { bidder: 'ix', params: {siteId: 467063, size: [360,50]} },
        { bidder: 'ix', params: {siteId: 438878, size: [360,50]} },
        //{ bidder: 'justpremium', params: {zone: 115710, allow: ["ms"]} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        //{ bidder: 'openx', params: { unit: '540973702', delDomain: 'lqr-d.openx.net' } },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_fr_mobile_StickyBannerMobile' } },
        //{ bidder: 'richaudience', params: { pid: '0mQBTSlzWP', supplyType: 'site' }},
        { bidder: 'sovrn', params:{ tagid: 793293 } },
        { bidder: 'teads', params: {pageId: '113723', placementId: '131124'} },
        { bidder: 'triplelift', params: { inventoryCode: "TravelcatchersFR_StickyBannerMobile_Prebid"} },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        { bidder: 'smartadserver', params: { siteId: 536188, pageId: 1662538, formatId: 116617} },
        { bidder: 'taboola', params: { tagId: 'StickyBanner', publisherId:  '1493292' } },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847493' } },
        { bidder: 'sharethrough', params: {pkey: 'tyXZK8U26mTQKaO3G8EJO2Vi'} },
        { bidder: 'sharethrough', params: {pkey: 'j4xi541ae6bGwsNfpB63hjmO'} }
      ]
    },
    {
      "condition": window.screen.width < 812 && !checkUtmSourceStartsWith('fb-'),
      "id": "MPUCenterRight",
      "name": MPUCenterRightUnitName,
      "path": `/76730613/${MPUCenterRightPathway}`,
      "sizes": [[300, 250],[336,280],[320,100],[1,1]],
      "mobile_bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-fr', adUnitElementId: 'MPUCenterRight', environment:'mobile', placement:`${MPUCenterRightPathway}` } },
        { bidder: 'adform', params: { mid: 951075 } },
        //{ bidder: 'appnexus', params: { placementId: '18453921'  } },
        { bidder: 'criteo', params: { networkId: 8147 } },
       // { bidder: 'openx', params: { unit: '540973694',  delDomain: 'lqr-d.openx.net'  } },
        { bidder: 'ix', params: {  siteId: 467059, size: [300, 250] } },
        { bidder: 'ix', params: {  siteId: 467059, size: [336, 280] } },
        //{ bidder: 'ix', params: {  siteId: 467059, size: [320, 50] } },
        { bidder: 'ix', params: {  siteId: 467059, size: [320, 100] } },
        //{ bidder: 'ix', params: {  siteId: 342236, size: [300, 100] } },
        //{ bidder: 'justpremium', params: { zone: 115710, allow: ["ms"] }},
        //{ bidder: 'districtm',  params: { placementId: 16005365 } },
        //{ bidder: 'districtmDMX', params: {  dmxid: 367616, memberid : 101868 } },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_fr_mobile_MPUCenterRight' } },
        //{ bidder: 'richaudience', params: { pid: '0bPcLlibPK', supplyType: 'site' }},
        { bidder: 'sovrn', params:{ tagid: 793297 } },
        { bidder: 'teads', params: {pageId: '113723', placementId: '131124'} },
        { bidder: 'triplelift', params: {inventoryCode: "TravelcatchersFR_mobile_MPUCenterRight_Prebid"} },
        //{ bidder: 'ogury', params: { assetKey: 'OGY-7D63387D8643', adUnitId: 'c7405b40-17e3-013b-a60e-4fbd3db95f28', skipSizeCheck: true } },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        { bidder: 'smartadserver', params: { siteId: 536188, pageId: 1662538, formatId: 116592} },
        { bidder: 'taboola', params: { tagId: 'MPUCenterRight', publisherId:  '1493292' } },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847493' } },
        { bidder: 'sharethrough', params: {pkey: 'fzORhv0ZGweYIgtKYadvKgwG'} },
        { bidder: 'sharethrough', params: {pkey: 'yw8oRrdphqYRiE5vZVzpp8RS'} }
      ]
    },
    {
      "condition": window.screen.width > 812,
      "id": "LeaderDesktopInfinite",
      "name": LeaderDesktopInfiniteUnitName,
      "path": `/76730613/${LeaderDesktopInfinitePathway}`,
      "sizes": [[728,90]],
      "bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-fr', adUnitElementId: 'LeaderDesktopInfinite', environment:'desktop', placement:`${LeaderDesktopInfinitePathway}` } },
        { bidder: 'adform', params: { mid: 996899 } },
        //{ bidder: 'appnexus', params: { placementId: '19308699'  } },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix', params: {  siteId: 509585, size: [728, 90] } },
        ////{ bidder: 'justpremium', params: { zone: 115710 }},
        //{ bidder: 'districtm',  params: { placementId: 16005365 } },
        //{ bidder: 'districtmDMX', params: {  dmxid: 367616, memberid : 101868 } },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        //{ bidder: 'openx', params: { unit: '541076958',  delDomain: 'lqr-d.openx.net'  } },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_fr_desktop_LeaderDesktopInfinite' } },
        //{ bidder: 'richaudience', params: { pid: '1k5bcGF8Lx', supplyType: 'site' }},
        { bidder: 'sovrn', params:{ tagid: 866880 } },
        { bidder: 'teads', params: {pageId: '113723', placementId: '131124'} },
        { bidder: 'triplelift', params: {inventoryCode: "TravelcatchersFR_desktop_LeaderDesktopInfinite_Prebid"} },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        { bidder: 'smartadserver', params: { siteId: 536188, pageId: 1662538, formatId: 116570} },
        { bidder: 'taboola', params: { tagId: 'LeaderDesktopInfinite', publisherId:  '1493292' } },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847493' } },
        { bidder: 'sharethrough', params: {pkey: 'hwf6ExImQsFyhbPnOiUsc7f4'} }
      ],
    },
    {
      "condition": false,
      "id": "MPU_Parallax",
      "name": MPUParallaxUnitName,
      "path": `/76730613/${MPUParallaxPathway}`,
      "sizes": [[336, 280], [320, 480], [300, 250], [300,600], [160,600], [120,600], [320,50]],
      "bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-fr', adUnitElementId: 'MPU_Parallax', environment:'mobile', placement:'TVLFR/MOB_MPUParallax' } },
        { bidder: 'adform', params: { mid: 1131524 } },
        { bidder: 'criteo', params: { networkId: 8147 } },
        //{ bidder: 'openx', params: { unit: '540973696', delDomain: 'lqr-d.openx.net' } },
        { bidder: 'ix', params: {siteId: 715656, size: [300,250]} },
        { bidder: 'ix', params: {siteId: 715656, size: [320,480]} },
        { bidder: 'ix', params: {siteId: 715656, size: [300,250]} },
        { bidder: 'ix', params: {siteId: 715656, size: [300,600]} },
        { bidder: 'ix', params: {siteId: 715656, size: [160,600]} },
        { bidder: 'ix', params: {siteId: 715656, size: [320,50]} },
        { bidder: 'ix', params: {siteId: 715656, size: [120,600]} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'sovrn', params:{ tagid: 955128 }},
        { bidder: 'sovrn', params:{ tagid: 955131 }},
        { bidder: 'sovrn', params:{ tagid: 955132 }},
        { bidder: 'sovrn', params:{ tagid: 955134 }},
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_fr_mweb_parallax' } },
        // { bidder: 'teads', params: { pageId: '113723', placementId: '135705'} },
        { bidder: 'teads', params: { pageId: '113723', placementId: '131124'} },
        { bidder: 'triplelift', params: {inventoryCode: 'TravelcatchersFR_mobile_MPUParallax_Prebid'} },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        { bidder: 'smartadserver', params: { siteId: 536188, pageId: 1662538, formatId: 116602} },
        { bidder: 'taboola', params: { tagId: 'MPU_Parallax', publisherId:  '1493292' } },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847493' } },
        { bidder: 'sharethrough', params: {pkey: 'NJliiKunphA0n3OQ0GO2dQMd'} },
        { bidder: 'sharethrough', params: {pkey: 'Q3NX8YidfkhMApaXg2V1Cdng'} },
        { bidder: 'sharethrough', params: {pkey: 'pAItTYeEqlcTzOpgr8HshQVR'} },
        { bidder: 'sharethrough', params: {pkey: 'kpy3wfI5bZ3WzSjnQojXPzIp'} },
        { bidder: 'sharethrough', params: {pkey: 'djxaBkdNf7n5vfYUIli77vgR'} },
        { bidder: 'sharethrough', params: {pkey: 'dEqI0jkW8HcLavo2jnuil9hy'} },
      ],
    },
    {
      "condition":  window.screen.width < 812 && !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_') && !checkUtmSourceStartsWith('fb-'),
      "id": "LeaderBelowTitle",
      "name": LeaderBelowTitleUnitName,
      "path": `/76730613/${LeaderBelowTitlePathway}`,
      "sizes": [[300, 250],[336,280],[320,100]],
      "bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-fr', adUnitElementId: 'LeaderBelowTitle', environment:'desktop', placement:`${LeaderBelowTitlePathway}` } },
        { bidder: 'adform', params: { mid: 951163 } },
        //{ bidder: 'appnexus', params: { placementId: '18453917' } },
        { bidder: 'criteo', params: { networkId: 8147 } },
        //{ bidder: 'openx',  params: { unit: '540973692', delDomain: 'lqr-d.openx.net' } },
        //{ bidder: 'districtm', params: { placementId: 16005304  } },
        //{ bidder: 'districtmDMX', params: { dmxid: 367610, memberid : 101868 } },
        { bidder: 'ix', params: {siteId: 467054, size: [728,90]} },
        ////{ bidder: 'justpremium', params: { zone: 115710 }},
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_fr_desktop_Leader_bellowTitle' } },
        //{ bidder: 'richaudience', params: { pid: 'paeUO1Jbal', supplyType: 'site' }},
        { bidder: 'sovrn', params:{ tagid: 793295 } },
        { bidder: 'teads', params: {pageId: '113723', placementId: '131124'} },
        { bidder: 'triplelift',  params: { inventoryCode: "TravelcatchersFR_Leader_bellowTitle_Prebid" } },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        { bidder: 'smartadserver', params: { siteId: 536188, pageId: 1662538, formatId: 116569} },
        { bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId:  '1493292' } },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847493' } },
        { bidder: 'sharethrough', params: {pkey: 'Yve4m9UqTGNKIwlDr1iyjbtT'} }
      ],
      "mobile_bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-fr', adUnitElementId: 'LeaderBelowTitle', environment:'mobile', placement:`${LeaderBelowTitlePathway}` } },
        { bidder: 'adform', params: { mid: 951072 } },
        //{ bidder: 'appnexus', params: { placementId: '18453917'  }  },
        { bidder: 'criteo', params: { networkId: 8147 } },
        //{ bidder: 'districtm', params: { placementId: 16005304  } },
        //{ bidder: 'districtmDMX', params: { dmxid: 367610, memberid : 101868 } },
        { bidder: 'ix', params: {siteId: 467060, size: [300,250]} },
        { bidder: 'ix', params: {siteId: 467060, size: [336,280]} },
        //{ bidder: 'ix', params: {siteId: 467060, size: [320,50]} },
        { bidder: 'ix', params: {siteId: 467060, size: [320,100]} },
        //{ bidder: 'ix', params: {siteId: 342243, size: [300,100]} },
        ////{ bidder: 'justpremium', params: { zone: 115710, allow: ["ms"] }},
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        //{ bidder: 'openx',  params: { unit: '540973692', delDomain: 'lqr-d.openx.net' } },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_fr_mobile_Leader_bellowTitle' } },
        //{ bidder: 'richaudience', params: { pid: '0N7ZcV2Atl', supplyType: 'site' }},
        { bidder: 'sovrn', params:{ tagid: 794527 } },
        { bidder: 'teads', params: {pageId: '113723', placementId: '135705'} },
        { bidder: 'triplelift', params: { inventoryCode: "TravelcatchersFR_mobile_Leader_bellowTitle_Prebid" } },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        { bidder: 'smartadserver', params: { siteId: 536188, pageId: 1662538, formatId: 116591} },
        { bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId:  '1493292' } },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847493' } },
        { bidder: 'sharethrough', params: {pkey: 'nZS4gozxQqv5RiF1ahBeEFIQ'} },
        { bidder: 'sharethrough', params: {pkey: 'HxrppTWk10gBbvjyr0YVOqP1'} }
      ]
    },
    {
      "condition": window.screen.width < 812 && !checkUtmSourceStartsWith('fb-'),
      "id": "MPUTopRight",
      "name": MPUTopRightUnitName,
      "path": `/76730613/${MPUTopRightPathway}`,
      "sizes": [[300, 250],[336,280],[320,100]],
      "mobile_bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-fr', adUnitElementId: 'MPUTopRight', environment:'mobile', placement:`${MPUTopRightPathway}` } },
        { bidder: 'adform', params: { mid: 951074 } },
        //{ bidder: 'appnexus', params: { placementId: '18453918' } },
        { bidder: 'criteo', params: { networkId: 8147 } },
        //{ bidder: 'openx', params: { unit: '540973695', delDomain: 'lqr-d.openx.net' } },
        //{ bidder: 'districtm',  params: { placementId: 16005318 } },
        //{ bidder: 'districtmDMX', params: { dmxid: 367611, memberid : 101868  } },
        { bidder: 'ix', params: { siteId: 467056, size: [300, 250] } },
        { bidder: 'ix', params: { siteId: 467056, size: [336, 280] } },
        //{ bidder: 'ix', params: { siteId: 467056, size: [320, 50] } },
        { bidder: 'ix', params: { siteId: 467056, size: [320, 100] } },
        //{ bidder: 'ix', params: { siteId: 500178, size: [300, 100] } },
        ////{ bidder: 'justpremium', params: { zone: 115710 }},
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_fr_mobile_MPUTopRight' } },
        //{ bidder: 'richaudience', params: { pid: '0yc6bSpu0D', supplyType: 'site' }},
        { bidder: 'sovrn', params:{ tagid: 793296 } },
        { bidder: 'teads', params: {pageId: '113723', placementId: '135705'} },
        { bidder: 'triplelift', params: {inventoryCode: "TravelcatchersFR_mobile_MPUTopRight_Prebid"} },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        { bidder: 'smartadserver', params: { siteId: 536188, pageId: 1662538, formatId: 116594} },
        { bidder: 'taboola', params: { tagId: 'MPUTopRight', publisherId:  '1493292' } },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847493' } },
        { bidder: 'sharethrough', params: {pkey: 'OFwYUkpB6tLKJz6lQhB8Pdja'} }
      ]
    },
  ];
  /**
   * Finds left and right columns, pushes the missing bidders
   * and pushes correct bidders to the adunit
   */
  // prettier-ignore
  if (window.screen.width >= 1100) {
    let rcMissingIX = adUnits.find(ad => ad.id === 'RightColumn');
    // Right
    rcMissingIX.bidders.push({ bidder: 'ix', params: {siteId: 467052, size:[300, 600]} });
    rcMissingIX.bidders.push({ bidder: 'ix', params: {siteId: 467052, size:[300, 250]} });
    rcMissingIX.bidders.push({ bidder: 'sovrn', params:{ tagid: 1103053 } });
  }
  return adUnits;

  function checkStickybanner() {
    if (
      !(checkUtmSourceStartsWith("fb-") && !checkUtmSourceStartsWith("twtr_"))
    ) {
      return window.screen.width < 812;
    } else {
      return false;
    }
  }
}
